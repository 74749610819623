<template>
      <div class="container-fluid">
        <div class="row">

            <sidebar></sidebar>
            <!-- MAIN CONTENT start -->
            <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10 h-100 pb-5">   
                <div class="row">
                    <div class="col-12 mb-5">
                        <app-header></app-header>
                        <page-header :page-title="pageData.title" :breadcrumb="pageData.breadcrumb"></page-header>
                    </div>
                </div>  
               
              
              
            </main>
        </div>
    </div>
</template>
<script>

import sidebar from '../components/navbar/sidebar';
import appHeader from '../components/app-header';
import pageHeader from '../components/page/page-header';


export default {
    name:'university-charter',
    components: { sidebar, appHeader, pageHeader },
    computed: {
        pageData() {
        return {
            title: this.$t('university-charter.title'),
            breadcrumb: [{
            icon: '',
            title: this.$t('home.title'),
            link: '/',
            isActive: false,
            },
            {
            icon: '',
            title: this.$t('university-charter.title'),
            link: '/university-charter',
            isActive: true,
            }],
        }; 
        },
    },
  
}
</script>
